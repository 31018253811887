import { jsEscape } from 'utils/prevent-xss-attack'
import { AllAllowedCookies, AllowedParam } from 'models/cookies'
import { setCookieLangFromUrl, SupportedLanguages } from 'utils/current-language'
import { allowedParamsIds, allowedReferalParams } from 'config/allowedCookies'
import sessionStorageService, { getValidParamsToStore } from './session-service'
import { isPanelUrl } from 'components/cookies-to-url'
import { clearUrlParamsIfNoMarketingConsent, decodeUrlParams } from '../utils/urls'

interface CookiesArray {
  [key: string]: string
}

declare const CMS_BASE_URL_WT: string

export const setupCookies = (): void => {
  cookieService.setupCookiesFromURL(allowedParamsIds, decodeUrlParams(window.location.href))
  setCookieLangFromUrl()
  sessionStorageService.saveCookiesFromURL(allowedReferalParams, decodeUrlParams(window.location.href))
  clearUrlParamsIfNoMarketingConsent()
}

export const getAllowedCookies = (allowedCookies: AllAllowedCookies[]): CookiesArray => {
  const cookiesArray: CookiesArray = {}
  allowedCookies.forEach((item) => {
    const cookieValue = cookieService.getItem(item)
    if (cookieValue) cookiesArray[item] = cookieValue
  })
  return cookiesArray
}

export const createUrlWithParams = (cookiesToParam: CookiesArray, url: URL) => {
  if (isPanelUrl(url)) {
    url.searchParams.delete('lang')
  }
  const separator = url.href.includes('?') ? '&' : '?'
  const params = new URLSearchParams(Object.entries(cookiesToParam).map(([key, value]) => [key, String(value)]))
  if (isPanelUrl(url)) {
    let urlWithParams = ''
    if (params.has('language')) {
      urlWithParams = url.href.includes('#/')
        ? url.href.replace('#/', `${separator}lang=${params.get('language')}#/`)
        : `${url.href}${separator}lang=${params.get('language')}#/`
      params.delete('language')
      return params.toString() ? `${urlWithParams}?${params.toString()}` : urlWithParams
    }
    urlWithParams = url.href.includes('#/') ? url.href : `${url.href}#/`
    return params.toString() ? `${urlWithParams}?${params.toString()}` : urlWithParams
  }

  return url.href + separator + params.toString()
}

export function validateParam(param: AllowedParam, value: any): boolean {
  if (param.type === 'boolean') {
    try {
      return JSON.parse(value) === true || JSON.parse(value) === false
    } catch (e) {
      return false
    }
  }
  if (param.type === 'string') {
    if (typeof value !== 'string' || value === 'true' || value === 'false') {
      return false
    }

    if (param.name === 'lang' && param.allowedValues) {
      const allowedLanguages = Object.values(SupportedLanguages)
      return allowedLanguages.includes(value as SupportedLanguages)
    }

    return true
  }
  return false
}

export function getCookieDomain(domain: string): string {
  const hostname = (domain || location.hostname).replace(/^(https?:\/\/)?(www\.)?/, '.')
  const environment = CMS_BASE_URL_WT.includes('cms')
    ? 'cms'
    : CMS_BASE_URL_WT.includes('test')
    ? 'test'
    : CMS_BASE_URL_WT.includes(process.env.STAGE_STATIC_COM_HOSTNAME)
    ? process.env.STAGE_STATIC_COM_HOSTNAME
    : CMS_BASE_URL_WT.includes(process.env.STAGE_STATIC_HOSTNAME)
    ? process.env.STAGE_STATIC_HOSTNAME
    : 'prod'

  if (environment === process.env.STAGE_STATIC_HOSTNAME)
    return `${hostname.replace(process.env.STAGE_STATIC_COM_HOSTNAME, process.env.STAGE_STATIC_HOSTNAME)}`
  return hostname
}

export function doesUrlContainParam(key: string, urlParamsObject?: object): boolean {
  urlParamsObject = urlParamsObject || getParamsFromUrl()
  return !!urlParamsObject[key]
}

export function getParamsFromUrl(): object {
  const urlParams = new URLSearchParams(window.location.search)
  return Array.from(urlParams.keys()).length > 0 ? Object.fromEntries(urlParams.entries()) : {}
}

const cookieService = {
  maximalCookieTTL: 90,
  setItem: (key: string, value: string, days = 365, domain = '', sameSite = 'Lax'): void => {
    const currentDate = new Date()
    currentDate.setTime(+currentDate + days * 86400000)

    document.cookie = `${key}=${value}; path=/; domain=${getCookieDomain(
      domain,
    )}; expires=${currentDate.toUTCString()}; SameSite=${sameSite}; Secure`
  },
  getItem: (name: string): string | null => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    return parts.length === 2 ? parts.pop().split(';').shift() : null
  },
  removeItem(key: string): void {
    document.cookie = `${key}=; path=/`
  },
  removeCookie(key: string, domain = ''): void {
    const $zeroTime = new Date(0)
    document.cookie = `${key}=; path=/ domain=${getCookieDomain(domain)}; expires=${$zeroTime.toUTCString()}`
  },
  setCookieByParamUrl(paramKey: string, cookieKey: string): void {
    const urlParamsObject = getParamsFromUrl()
    if (doesUrlContainParam(paramKey, urlParamsObject))
      cookieService.setItem(cookieKey, urlParamsObject[paramKey], cookieService.maximalCookieTTL)
  },
  setupCookiesFromURL: (allowedParamsIds: AllowedParam[], decodedParams?: Record<string, string>): boolean => {
    const validParamsToStore = getValidParamsToStore(allowedParamsIds, decodedParams)
    let cookiesSet = false

    for (const paramName in validParamsToStore) {
      if (doesUrlContainParam(paramName, validParamsToStore)) {
        const cookieName = paramName === 'xchangerId' ? 'xchanger_id' : paramName

        cookieService.setItem(
          jsEscape(cookieName),
          jsEscape(validParamsToStore[paramName]),
          cookieService.maximalCookieTTL,
        )

        cookiesSet = true
      }
    }

    return cookiesSet
  },
  setTrasactCookies(paramKey: string[], cookieKey: string): void {
    const urlParamsObject = getParamsFromUrl()
    if (doesUrlContainParam('ref')) {
      const cookieContent = paramKey.reduce((acc, key) => {
        return doesUrlContainParam(key) && urlParamsObject[key] !== '' ? `${acc}&${key}=${urlParamsObject[key]}` : acc
      }, `${urlParamsObject['ref']}`)
      const finalCookieContent = cookieContent.startsWith('&') ? cookieContent.slice(1) : cookieContent
      cookieService.setItem(cookieKey, finalCookieContent, cookieService.maximalCookieTTL)
    }
  },
  setupCookiesFromClient: (): void => {
    if (cookieService.getItem('ik_referrer')) {
      // TODO: Check is this code relevant for us.
      cookieService.setItem('ik_referrer', document.referrer, 730)
    }
  },
}

export default cookieService
