import cookieService from 'services/cookie-service'
import { decodeUrlParams } from 'utils/urls'
import { allowedReferalParams, allowedParamsRefvisIds } from 'config/allowedCookies'
import sessionStorageService, { storeSessionMarketingItemsAsCookies } from 'services/session-service'

declare global {
  interface Window {
    CMS_BASE_URL_WT: string
    CMS_BASE_URL_WT_COM: string
  }
}

const setupRefvisCookie = (): void => {
  const params = decodeUrlParams(window.location.href)
  // Extra URL params for media partners
  // (uap === bankierid)
  const partnerParams = ['uap', 'at_gd', 'xchangerId']
  const allowedParamNames = allowedParamsRefvisIds.map((ap) => ap.name)

  const refvis = [...allowedParamNames, ...partnerParams].reduce((acc, curr) => {
    const paramValue = params[curr]

    if (!paramValue) return acc
    if (curr.includes('utm')) return acc ? `${acc}_${paramValue}` : `${paramValue}`
    return `${acc}&${curr}=${paramValue}`
  }, '')

  if (refvis) {
    cookieService.setItem('refvis', refvis, cookieService.maximalCookieTTL)
  }
}

function handleRefererCode(): void {
  const params = new URLSearchParams(window.location.search)
  const refererCode = params.get('refererCode')
  if (refererCode) {
    cookieService.setItem('refererCode', refererCode, cookieService.maximalCookieTTL)
  }
}

// Cookies
function setupCookiesMarketing(): void {
  const urlParams = new URLSearchParams(window.location.hash.substring(2))
  const isEncoded = urlParams.has('data')
  const cookiesFromUrl = cookieService.setupCookiesFromURL(
    allowedReferalParams,
    isEncoded ? decodeUrlParams(window.location.href) : undefined,
  )
  cookieService.setupCookiesFromClient()
  setupRefvisCookie()
  handleRefererCode()
  if (!cookiesFromUrl) {
    storeSessionMarketingItemsAsCookies(allowedReferalParams)
  }
  sessionStorageService.removeItems(allowedReferalParams)
  history.replaceState(null, '', window.location.pathname)
}

setupCookiesMarketing()
